$width: calc(100vw - 30px);

.container {
  padding: 25px;
  background: #f4f7fe;
  border-radius: 25px;
  margin-top: 75px;
}

.list-container {
  display: flex;
  max-width: $width;
  width: 100%;
  overflow: hidden;
  border-top: 1px solid rgba(102, 109, 129, 0.3);
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
  position: relative;
  border-radius: 25px;
  padding: 0 48px;
  z-index: 98;
}
.pointer {
  position: absolute;
  height: 100%;
  width: 48px;
  background: #ebf1ff;
  border: 1px solid rgba(102, 109, 129, 0.3);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > i {
    color: rgba(102, 109, 129, 0.3);
  }
}
.pointer:first-child {
  left: 0;
  border-radius: 25px 0px 0px 25px;
}
.pointer:nth-last-child(1) {
  right: 0;
  border-radius: 0px 25px 25px 0px;
}
.select-card,
.card-container {
  padding: 20px;
  width: calc(100% / var(--numberOfCards));
  height: 400px;
  background: #f4f7fe;
  border: 1px solid rgba(102, 109, 129, 0.3);
  overflow: hidden;
  overflow-y: scroll;
  flex-shrink: 0;
  transition: transform 0.5s;
  transform: translateX(calc(var(--activeIndex) * 100%));

  p.label-style {
    font: var(--contentMedium);
    font-weight: 400;
    display: flex;
    align-items: center;
    color: #666d81;
    width: 100%;
    padding: 5px;
    cursor: pointer;
    > span {
      flex: 1 1;
      text-transform: capitalize;
    }
    > i {
      font-size: 16px;
      color: rgba(102, 109, 129, 0.5);
    }
  }
  p.label-style:hover,
  p.label-style[data-activeTab='true'] {
    background: #ff9801;
    color: #ffffff;
    > i {
      color: #ffffff;
    }
  }
}
.select-card {
  background: #ffffff;
  border: 1px solid rgba(102, 109, 129, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% / var(--numberOfCards));
  color: #666d81;
  font: var(--contentMedium);
  text-transform: capitalize;

  > div {
    margin-top: 10px;
  }
}

.helper-style {
  font: var(--contentSmall);
  font-weight: 700;
  color: #48acb2;
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.stepper-container {
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  > section {
    align-self: flex-start;
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
.status-style {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #06bf65;
  margin-left: 10px;
}
.message-container {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
  margin-bottom: 10px;
}
