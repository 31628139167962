.container {
}

.image-container {
  width: 100%;
  height: 372px;
  background-size: cover !important;
  background-repeat: no-repeat;
  position: relative;
  background: #00031c;
  .image-overlay[data-user='true'] {
    background: #e8feff;
    p {
      color: #131d3b;
      margin-bottom: 10px;
      line-height: 22px;
    }
    .location-container {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      section:first-child {
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 700;
        color: #131d3b;
      }
      section:nth-child(2) {
        color: #131d3b;
      }
      section:nth-child(2)::before {
        content: ':';
        margin-right: 5px;
      }
    }
    .location-container3 {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      section:first-child {
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #131d3b;
      }
      section:nth-child(2) {
        color: var(--theme_secondary);
        font-weight: 700;
        cursor: pointer;
      }
      section:nth-child(2)::before {
        content: '';
        margin-right: 5px;
      }
      section:nth-child(2)::after {
        content: '>';
        margin-left: 5px;
      }
    }
  }
  .image-overlay {
    padding: 93px 54px 46px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    color: #ffffff;
    background: linear-gradient(
      69.81deg,
      #00031c 17.94%,
      rgba(0, 3, 28, 0) 78.17%
    );
    font: var(--contentVerySmall);
    p {
      color: #ffffff80;
      margin-bottom: 10px;
      line-height: 22px;
    }

    .location-container {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      section:first-child {
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 700;
      }
      section:nth-child(2) {
        color: #ffffff80;
      }
      section:nth-child(2)::before {
        content: ':';
        margin-right: 5px;
      }
    }
    .location-container3 {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      section:first-child {
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      section:nth-child(2) {
        color: var(--theme_secondary);
        font-weight: 700;
        cursor: pointer;
      }
      section:nth-child(2)::before {
        content: '';
        margin-right: 5px;
      }
      section:nth-child(2)::after {
        content: '>';
        margin-left: 5px;
      }
    }
  }
}
.btn-container {
  width: 75%;
  max-height: 29px;
}

.update-banner-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}
.review-container {
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: calc(100% - 46px);
  padding: 15px;
}
.chip-container {
  display: flex;
  align-items: center;
  gap: 13px;
  .chip {
    padding: 5px 20px;
    font: var(--contentMedium);
    color: #131d3b;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    text-align: center;
    cursor: pointer;
  }
  .chip[data-active='true'] {
    color: #ffffff;
    background: var(--theme_secondary);
  }
}
.svg-icon[data-active='true'] {
  path {
    fill: var(--theme_secondary);
  }
}
.rating-container {
  font: var(--contentMedium);
  font-weight: 700;
  margin: 10px 0 45px;
  color: #131d3b;
  display: flex;
  align-items: center;
  gap: 2px;
  > span {
    display: inline-block;
  }
}
.icon-container {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  padding-top: 5px;
  gap: 20px;
  > div {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font: var(--contentSmall);
    color: #131d3b;
    flex-direction: column;
    cursor: pointer;
    > span {
      width: 30px;
      height: 30px;
      background-color: var(--theme_secondary);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;
      svg {
        width: 20px;
        height: 20px;
        path {
          fill: #ffffff;
        }
      }
    }
  }
}

.user-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #ffffff;
}
.content-container {
  display: flex;
  align-items: center;
  gap: 15px;
  h3 {
    font: var(--contentLarge);
    font-weight: 700;
    margin: 10px 0;
  }
}

.attention {
  transform: rotate(180deg);
  margin-left: 5px;
  &-admin {
    path {
      fill: red;
    }
  }
  &-author {
    path {
      fill: orange;
    }
  }
}

.directory-type {
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
  max-height: 35px;
}
.directory-type[data-directory*='business'i] {
  background: #48acb2;
  color: #ffffff;
}
.directory-type[data-directory*='ngo'i] {
  background: #05e8b1;
  color: #ffffff;
}
