.container {
  width: 100%;
  margin-bottom: 5px;
  > label {
    font: var(--contentMedium);
    font-weight: 700;
    line-height: 19px;
    color: #131d3b;
    display: block;
    margin-bottom: 5px;
    > span {
      color: #ff5555;
    }
  }
}
.file-area[data-disabled='true'] {
  background: rgba(19, 29, 59, 0.05);
}
.file-area {
  width: 100%;
  border: 1px solid #48acb2;
  position: relative;
  height: 188px;
  border-radius: 10px;

  input[type='file'] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0;
  }
  > section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  > section > i {
    color: #48acb2;
    font-size: 100px;
    display: block;
    font-weight: 100;
  }
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(var(--layout-size), 1fr);
  gap: 25px;
  .image-container > img {
    width: 100%;
    height: 188px;
    border-radius: 20px;
    object-fit: cover;
  }
  .image-container > i {
    display: flex;
    background-color: #f4f7fe;
    color: #666d81;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    cursor: pointer;
  }
  .image-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
@media screen and(max-width:1280px) {
  .grid-layout {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and(max-width:769px) {
  .grid-layout {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and(max-width:576px) {
  .grid-layout {
    grid-template-columns: repeat(1, 1fr);
  }
}
