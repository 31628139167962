.left-container {
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: absolute;
  height: calc(100% - 17px);
  width: 40%;
  z-index: 999;
  top: 17px;
  overflow: hidden;
}

.header-container {
  display: flex;
  gap: 30px;
  padding: 0 0 20px;
  > div {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}

.list-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100% - 100px);
  overflow-y: scroll;
}
