body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto Condensed';
}

code {
  font-family: 'Roboto Condensed';
}
html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

.page-heading {
  font-size: 22px !important;
  font-family: 'Roboto Condensed';
}
.section-heading {
  font-size: 20px !important;
  font-family: 'Roboto Condensed';
}
.form-label {
  font-size: 16px;
  font-family: 'Roboto Condensed';
}
.normal-text {
  font-size: 14px;
  font-family: 'Roboto Condensed';
}
.small-text {
  font-size: 12px;
  font-family: 'Roboto Condensed';
}
:root {
  --theme_secondary: #ff9801;

  --headingLarge: 700 32px 'Roboto Condensed';
  --headingMedium: 700 24px 'Roboto Condensed';
  --headingSmall: 700 16px 'Roboto Condensed';
  --headingVerySmall: 700 12px 'Roboto Condensed';

  --contentExtraLarge: 22px 'Roboto Condensed';
  --contentLarge: 20px 'Roboto Condensed';
  --contentMedium: 16px 'Roboto Condensed';
  --contentSmall: 14px 'Roboto Condensed';
  --contentVerySmall: 12px 'Roboto Condensed';
  --contentTiny: 10px 'Roboto Condensed';
  --contentExtraLargeB: 700 22px 'Roboto Condensed';
  --contentLargeB: 700 20px 'Roboto Condensed';
  --contentMediumB: 700 16px 'Roboto Condensed';
  --contentSmallB: 700 14px 'Roboto Condensed';
  --contentVerySmallB: 700 12px 'Roboto Condensed';

  --mobeMaxWidth: 480px;
  --mobeMaxWidth2: 768px;
  --containerMargin: 70px 65px 30px 65px;
  --containerMarginTop: 0;
  --containerPadding: 30px 70px;
  --containerPaddingLarge: 30px 280px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e2e2e2;
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar {
  background: #ffffff;
  width: 11px;
}
