.container {
  background: url('../../../../libs/assests/orange-background.png');
  width: 100%;
  padding: var(--containerPaddingLarge);
  min-height: 300px;
  background-position: 25% 10%;
  padding-top: 50px;
  padding-bottom: 50px;
  .left-container {
    > p {
      font: var(--headingLarge);
      color: #ffffff;
    }
    > span {
      font: var(--contentMediumB);
      color: #091846;
    }
  }
}

.user-container {
  display: flex;
  gap: 20px;
  margin-top: 25px;
  div[data-active='true'] {
    opacity: 1;
  }
  div {
    opacity: 0.4;
    > p {
      font: var(--contentVerySmall);
      color: #ffffff;
      padding: 0;
      margin-bottom: 5px;
    }
    > span {
      font: var(--contentTiny);
      display: block;
      color: #091846;
    }
  }
  .image-container {
    display: inline-block;
    position: relative;
    > span {
      width: 20px;
      height: 20px;
      font-family: 'Roboto';
      color: #ffffff;
      border-radius: 50%;
      position: absolute;
      bottom: 5px;
      right: 0px;
      background-color: var(--theme_secondary);
      padding: 2px 5px 5px 5px;
    }
  }

  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-position: 50%;
    object-fit: cover;
    border: 3px solid #ffffff;
  }
}

.right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font: var(--contentSmall);
  font-family: 'Roboto';
  line-height: 22px;
  color: #ffffff;
  height: 100%;
  text-align: justify;
  > div {
    display: flex;
    gap: 5px;
    margin-bottom: 10px;
    flex: 1;
    > span {
      border: 4px solid #ffffff;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      opacity: 0.4;
      cursor: pointer;
    }
    > span[data-active='true'] {
      opacity: 1;
    }
  }
}
