.checkbox-label {
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  font: var(--contentMedium);
  color: #666d81;
}
.container {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  min-width: 210px;
  > span {
    display: inline-block;
  }
  .sub-label {
    font: var(--contentVerySmall);
  }
  > svg {
    width: 10px;
    height: 10px;
  }
  .sub-label,
  > svg {
    margin-left: 5px;
    color: rgba(102, 109, 129, 0.5);
  }
}

.checkbox-label[data-text-type='bold'i] {
  font-weight: 700;
  color: #131d3b;
}
