.container {
  font: var(--contentMedium);
  font-weight: 700;
  color: #131d3b;
  display: flex;
  align-items: center;
  > label {
    display: block;
    margin-left: 10px;
  }
}
