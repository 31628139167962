.card-list-container {
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
  margin: 10px 0 20px;
  overflow: hidden;
  overflow-x: auto;
  scroll-snap-align: start;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  > span {
    position: absolute;
    background: #ffffff;
    color: #131d3b;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    top: 45%;
    z-index: 100;
    box-shadow: 0px 8px 8.246px rgba(0, 0, 0, 0.1);
    > img {
      width: 20px;
      height: 20px;
    }
  }
  > span:first-child {
    left: 10px;
  }
  > span:nth-last-child(1) {
    right: 10px;
    transform: rotate(180deg);
  }
}

.card-list-container::-webkit-scrollbar {
  display: none;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px;
  border-top: 1px solid rgba(102, 109, 129, 0.3);
}

.section-container {
  position: relative;
  background: #ffffff;
  padding: var(--containerPadding);
}

.section-container[data-no-background='true'] {
  padding: var(--containerPadding);
  background: #f5f5f5;
}
.sub-container {
  display: flex;
  flex-direction: column;
}

.announcement-header {
  display: flex;
  justify-content: space-between;
}

.modal-content-container {
  border-left: 1px solid rgba(102, 109, 129, 0.3);
  padding-left: 0.5rem;
  margin-left: 0.5rem;
  height: 100%;
}
