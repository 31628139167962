.card-header {
  margin: 0 20px;
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
  > h3 {
    font: var(--contentLargeB);
    font-style: normal;
    line-height: 22px;
    color: #131d3b;
    margin-bottom: 10px;
  }
  > p {
    font: var(--contentSmall);
    color: #666d81;
    line-height: 16px;
    margin-bottom: 15px;
  }
  .helper-style {
    font: var(--contentVerySmall);
    line-height: 14px;
    color: #ff5555;
    margin-bottom: 5px;
    display: flex;
    justify-content: flex-end;
    > img {
      margin-right: 5px;
    }
  }
}
.card-header[data-mainheader='true'] {
  border-bottom: 0px solid #000;
  font: var(--headingMedium);
  color: #131d3b;
  margin: 0;
  margin-bottom: 24px;
  > h3 {
    font: var(--contentExtraLargeB);
    line-height: 22px;
    color: #131d3b;
    margin-bottom: 10px;
  }
}
