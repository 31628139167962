.chip {
  border-radius: 5px;
  font: var(--contentSmallB);
}

.chip[data-type='published'i] {
  color: #06bf65;
}
.chip[data-type*='pending'i] {
  color: #dcac0d;
}
