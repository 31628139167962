.container {
  padding: 10px 0;
}
.commenter-name {
  font: var(--contentMediumB);
  color: #131d3b;
}
.comment-date {
  margin-top: 2px;
  font: var(--contentVerySmall);
  color: rgba(102, 109, 129, 0.5);
}
.comment-content {
  margin-top: 5px;
  font: var(--contentSmall);
  color: #666d81;
}
