.footer-banner {
  background: #235963;
  width: 100%;
  padding: 50px 100px;
  h6 {
    font: var(--headingSmall);
    color: #ffffff;
    margin-bottom: 25px;
  }
  p {
    font: var(--contentSmall);
    line-height: 25px;
    color: #b6bbcc;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  ul li {
    font: var(--contentSmall);
    line-height: 25px;
    color: #b6bbcc;
  }
  #img-section {
    padding: 15px;
    border-radius: 50px;
  }
}
