.head-container {
  width: 100%;
  position: relative;

  > h3 {
    text-align: center;
    color: #131d3b;
    font: var(--contentExtraLargeB);
  }
  > div {
    position: absolute;
    right: 15px;
    top: 0;
  }
}
.card-inner-container {
  margin: 40px 70px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.container {
  background-color: rgba(72, 172, 178, 0.1);
  padding-top: 120px;
  padding-bottom: 30px;
  > div {
    position: relative;
    > span {
      position: absolute;
      background: #ffffff;
      color: #131d3b;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 30px;
      top: 35%;
      z-index: 1;

      box-shadow: 0px 8px 8.246px rgba(0, 0, 0, 0.1);
      > img {
        width: 20px;
        height: 20px;
      }
    }
    > span:first-child {
      left: 10px;
    }
    > span:nth-last-child(1) {
      right: 10px;
      transform: rotate(180deg);
    }
  }
}
