.error {
  background: rgba(255, 85, 85, 0.2);
  border-radius: 5px;
  font: var(--contentVerySmall);
  color: #ff5555;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.error > i {
  color: #ff5555;
  font-size: 14px;
  line-height: 0;
}
.error > span {
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 5px;
}
