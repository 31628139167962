.header-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  h3 {
    font: var(--contentLargeB);
  }
}
.container {
  margin-top: 10px;
}

.author-details {
  font: var(--contentVerySmall);
  display: flex;
  margin-top: 10px;
  div {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .author-name {
    color: var(--theme_secondary);
  }
  .article-date,
  .article-comment {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #666d81;
  }
}

.article-content {
  display: flex;
  flex: 1;
  margin-top: 10px;
  color: #666d81;
  font: var(--contentSmall);
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
