.container {
    > h3 {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 700;
      font-size: 50px;
      line-height: 59px;
      color: #131d3b;
      margin: 0;
      padding: 0;
      margin-bottom: 14px;
    }
    > p {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 38px;
      color: #666d81;
    }
  }
  .card-section {
    width: 1240px;
    background: #ffffff;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    padding: 34px 20px 34px 25px;
    .card-header {
      border-bottom: 1px solid rgba(102, 109, 129, 0.3);
      > h3,
      > p {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 50px;
        line-height: 59px;
        color: #131d3b;
        margin: 0;
        padding: 0;
        margin-bottom: 14px;
      }
      > p {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 24px;
        line-height: 38px;
        color: #666d81;
      }
      .helper-style {
        font-style: normal;
        font-weight: 400;
        font-size: 18.5px;
        line-height: 38px;
        color: #ff5555;
        margin-top: 25px;
        display: flex;
        justify-content: flex-end;
        > img {
          margin-right: 5px;
        }
      }
    }
    .card-body {
      > h3 {
        font-family: 'Roboto Condensed';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 35px;
        color: #131d3b;
        margin: 0;
        padding: 0;
        margin: 40px 0 24px;
        display: inline-block;
      }
      .helper-style {
        font-style: normal;
        font-weight: 400;
        font-size: 18.5px;
        line-height: 38px;
        margin: 0;
        padding: 0;
        color: rgba(102, 109, 129, 0.5);
        display: inline-block;
        > img {
          margin-right: 5px;
        }
      }
    }
  }
  
  .checkbox-label {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #666d81;
  }
  .check-box-group {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    > p {
      margin: 0;
      padding: 0;
      margin-right: 60px;
    }
  }
  
  .btn-container {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    border-top: 1px solid rgba(102, 109, 129, 0.3);
  }
  .card-container {
    display: flex;
    justify-content: center;
    margin-top: 90px;
  }
  .helper-style-1 {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #48acb2;
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  
  .status-style {
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #06bf65;
    margin-left: 10px;
  }
  .message-container {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    margin-bottom: 10px;
  }
  