.divider {
  border-bottom: 1px solid #666d81;
  padding-bottom: 10px;
}
.header-container {
  margin-bottom: 10px;
  div {
    display: flex;
    margin-bottom: 10px;
    > section {
      flex: 1;
      display: flex;
      align-items: center;
      color: #666d81;
      font: var(--contentSmall);
      > img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
    > span {
      color: #ff9801;
      font: var(--contentSmall);
      cursor: pointer;
    }
  }
  h3 {
    flex: 1;
    display: block;
    align-self: center;
  }
}
.container {
  margin: 10px 0;
  ul {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  ul > li {
    color: #666d81;
    display: flex;
    align-items: flex-start;
    font: var(--contentSmall);
    line-height: 22px;
    margin-bottom: 17px;
    > img {
      margin: 5px 10px 0 0;
      width: 15px;
      height: 15px;
    }
  }
}
.btn-container {
  display: flex;
  gap: 17px;
}
