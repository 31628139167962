.attention {
  transform: rotate(180deg);
  margin-left: 5px;
  &-admin {
    path {
      fill: #ff5555;
      fill-opacity: 1;
    }
  }
  &-author {
    path {
      fill: orange;
      fill-opacity: 1;
    }
  }
}
