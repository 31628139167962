.product-modal-list {
  column-count: 3;
  padding: 10px;
  column-rule: 1px solid rgba(37, 56, 142, 0.2);
  border-top: 1px solid rgba(102, 109, 129, 0.3);
  > div {
    display: flex;
    //justify-content: center;
    align-items: center;
    font: var(--contentSmall);
    margin-bottom: 10px;
    line-height: 20px;
    color: #666d81;
    white-space: wrap;
  }
  span {
    background: var(--theme_secondary);
    color: #ffffff;
    border-radius: 50%;
    font: var(--contentVerySmall);
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }
}
