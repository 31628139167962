.main-content-container {
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-top: 15px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.radio-container-1 {
  margin-top: 30px;
}
.radio-container {
  border-top: 1px solid rgba(102, 109, 129, 0.3);
  padding-top: 15px;
}
