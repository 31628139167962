.main-container {
  padding: 20px;
  background: #ffffff;
  border: 1px solid #c8c8c8;
  border-radius: 20px;
}

.header-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  > aside {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  > span {
    display: flex;
  }
}

.star-icon,
.location-icon {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  path {
    fill: var(--theme_secondary);
  }
}
.map-content {
  font: var(--contentSmallB);
  cursor: pointer;
}
.map-content,
.rating-container {
  color: var(--theme_secondary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.rating-container {
  padding: 5px;
  background: rgba(255, 152, 1, 0.1);
  border-radius: 5px;
  font: var(--contentMedium);
}
.rating-container + span {
  color: #666d81;
  font: var(--contentMedium);
}

.rating-container + span::after,
.rating-container + span::before {
  content: '';
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 10px;
}

.directory-type {
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
}
.directory-type[data-directory='business'i] {
  background: #448aff;
  color: #ffffff;
}
.directory-type[data-directory='ngo'i] {
  background: #06bf65;
  color: #ffffff;
}
.sub-content {
  font: var(--contentSmall);
  color: #666d81;
  margin: 10px 0 15px;
}

.icon {
  background: #235963;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  > span {
    position: absolute;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    background: var(--theme_secondary);
    border-radius: 50%;
    font: var(--contentSmall);
    top: 0;
    right: -5px;
  }
}

.icon-container {
  display: flex;
  gap: 20px;
}
