.btn {
  outline: none;
  padding: 5px 20px;
  font: var(--contentSmall);
  line-height: 19px;
  font-weight: 700;
  white-space: nowrap;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  text-transform: capitalize;
  i {
    font-size: 16px;
    color: #ffffff;
    margin-right: 5px;
  }
  img {
    max-width: 17px;
    max-height: 17px;
    margin-right: 5px;
  }
  svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
}
.btn[data-rounded='true'] {
  border-radius: 50px;
}

.full-width {
  width: 100%;
  padding: 10px;
}

.btn-primary,
.btn-secondary:hover {
  background: #ff9801;
  border: 2px solid #ff9801;
  color: #ffffff;
  box-shadow: 0px 5px 10px 3px rgba(255, 152, 1, 0.3);
  svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    path {
      fill: #ffffff;
    }
  }
}
.btn-secondary,
.btn-primary:hover {
  background: #f4f7fe;
  border: 2px solid #f4f7fe;
  color: #666d81;
  box-shadow: none;
  > i {
    color: #666d81;
  }
  svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    path {
      fill: #666d81;
    }
  }
}
.btn-tertiary {
  background: #ffffff;
  border: 2px solid #ffffff;
  color: #000000;
  > i {
    color: #000;
  }
}
.btn-red {
  border: 2px solid #ff5555;
  background: transparent;
  color: #ff5555;
  > i {
    color: #ff5555;
  }
  svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    path {
      fill: #ff5555;
    }
  }
}
.btn-red:hover {
  background: #ff5555;
  color: #ffffff;
  > i {
    color: #ffffff;
  }
  svg {
    path {
      fill: #ffffff;
    }
  }
}
.btn-green {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 17 px;
  gap: 10px;
  background: rgba(6, 191, 101, 0.2);
  border-radius: 5px;
  border: none;
  color: #06bf65;
}

.btn-orange {
  border: 2px solid #ff9801;
  background: transparent;
  color: #ff9801;
  > i {
    color: #ff9801;
  }
  svg {
    path {
      fill: #ff9801;
    }
  }
}
.btn-orange:hover {
  background: #ff9801;
  color: #ffffff;
  > i {
    color: #ffffff;
  }
  svg {
    path {
      fill: #ffffff;
    }
  }
}
