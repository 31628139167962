.container {
  font: var(--contentExtraLarge);
  font-weight: 700;
  color: #131d3b;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  > span {
    display: block;
    > span {
      color: #ff9801;
    }
  }
}
.sub-header-style {
  font: var(--contentVerySmall);
  color: #666d81;
  display: block;
  margin-bottom: 5px;
}
.sub-header-style[data-align='right'] {
  text-align: right;
}
.container[data-align='right'] {
  justify-content: flex-end;
}
.sub-header-style[data-carousel='true'],
.container[data-carousel='true'] {
  margin-left: 30px;
  margin-right: 30px;
}
