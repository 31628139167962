.container {
  width: 100%;
  margin: 0 0 10px;
  position: relative;
}
.input-container {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 5px;
  height: 36px;
  margin-bottom: 5px;
}

.container input {
  outline: none;
  border: 0px;
  border-radius: 10px;
  font: var(--contentSmall);
  font-weight: 400;
  line-height: 16px;
  color: #131d3b;
  width: 100%;
  background: none;
  padding: 0 5px 0 5px;
  min-height: 40px;
}
.container-disabled {
  background: rgba(19, 29, 59, 0.05);
  color: rgba(102, 109, 129, 0.5);
}
.container input::placeholder {
  font: var(--contentSmall);
  font-weight: 400;
  line-height: 16px;
  color: rgba(102, 109, 129, 0.5);
}
.container label {
  font: var(--contentMedium);
  font-weight: 700;
  line-height: 19px;
  color: #131d3b;
  display: block;
  margin-bottom: 5px;
}
label > span {
  color: #ff5555;
}

.container i {
  color: #48acb2;
  cursor: pointer;
  margin: 0 5px;
}
.delete-icon {
  border-radius: 100%;
  width: 35px;
  height: 35px;
  background: #f4f7fe;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: -45px;
  cursor: pointer;
}
