.container {
  > h2 {
    font: var(--headingMedium);
    color: #131d3b;
    margin: 0;
    margin-bottom: 24px;
  }
}

.card-container {
  width: 1240px;
  background: #ffffff;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  padding: 34px 0px 0px 0px;
}
