.container {
  background: #ffffff;
  padding: 5px 5px 15px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  h1 {
    font: var(--contentMedium);
    font-weight: 700;
    color: #131d3b;
    line-height: 28px;
    margin-bottom: 5px;
  }
  p {
    font: var(--contentSmall);
    color: #666d81;
    line-height: 20px;
    flex: 1;
    margin-bottom: 5px;
  }
  .image-container {
    position: relative;
    > img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}
.container[data-card='true'] {
  padding: 15px;
  max-height: 500px;
  .image-container {
    > img {
      height: 225px;
      border-radius: 2px;
    }
  }
  .comment-section {
    font: var(--contentVerySmall);
    display: flex;
    margin: 5px 0;
    > span {
      display: inline-block;
      color: #666d81;
    }
    > span:first-of-type {
      flex: 1;
      color: #ff9801;
    }
    > span:last-child::before {
      content: '-';
      margin: 0 2px;
    }
  }
}
.btn-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 20px;
}

.image-btn {
  position: absolute;
  top: 10px;
  left: 10px;
}
