.list-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.left-container {
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 20px;
}

.header-container {
  display: flex;
  gap: 30px;
  padding: 0 0 20px;
  > div {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
