.tab-container {
  display: flex;
  padding: 10px 0;
  border-left: 3px solid #ff9801;
  > p,
  .active-tab {
    font: var(--contentSmall);
    font-weight: 400;
    color: #666d81;
    cursor: pointer;
  }
  > p > span,
  .active-tab > span {
    border: 2px solid #666d81;
    border-radius: 50%;
    margin: 0 10px;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
  }
  > p::after {
    content: '>';
    margin-left: 10px;
  }
  .active-tab {
    color: #ff9801;
    cursor: pointer;
  }
  .active-tab > span,
  .visited-tab > span {
    background: #ff9801;
    border: 2px solid #ff9801;
    color: #ffffff;
  }
  img {
    width: 10px;
  }
}

.stepper-list-container {
  .stepper {
    font: var(--contentMedium);
    font-weight: 400;
    color: #ff9801;
    margin-right: 10px;
    padding-bottom: 10px;
    text-transform: capitalize;
    display: inline-block;
  }
  .stepper::after,
  .stepper:nth-last-child(1)::after {
    content: '>';
    padding-left: 5px;
  }
  .stepper:nth-last-child(1)::after {
    content: '';
  }
}
