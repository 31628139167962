.selected-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.container {
  width: 100%;
}

.image-container {
  width: 100%;
  position: relative;
  > span {
    position: absolute;
    background: #ffffff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    top: 35%;
  }
  > span:first-child {
    left: 20px;
  }
  > span:nth-last-child(1) {
    right: 20px;
  }
  > img {
    width: calc(100% / 4);
    height: 100px;
    object-fit: cover;
    position: cursor;
  }
}
