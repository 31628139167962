.enquiry-to {
  margin: 10px 0;
  font: var(--contentSmall);
  color: #666d81;
  > span:first-child {
    font: var(--contentMedium);
    font-weight: 700;
    color: #131d3b;
    margin-right: 5px;
  }
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px;
  border-top: 1px solid rgba(102, 109, 129, 0.3);
}
