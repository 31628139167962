.container {
  width: 100%;
  margin: 0 0 10px;
  position: relative;
}
.input-container {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  padding: 5px;
  height: 36px;
  margin-bottom: 5px;
}

.container input {
  outline: none;
  border: 0px;
  border-radius: 10px;
  font: var(--contentSmall);
  font-weight: 400;
  line-height: 16px;
  color: #131d3b;
  width: 100%;
  background: none;
  padding: 0 5px 0 5px;
  min-height: 40px;
}
.container-disabled {
  background: rgba(19, 29, 59, 0.05);
  color: rgba(102, 109, 129, 0.5);
}
.container input::placeholder {
  font: var(--contentSmall);
  font-weight: 400;
  line-height: 16px;
  color: rgba(102, 109, 129, 0.5);
}
.container label {
  font: var(--contentMedium);
  font-weight: 700;
  line-height: 19px;
  color: #131d3b;
  display: block;
  margin-bottom: 5px;
}
label > span {
  color: #ff5555;
}

.container i {
  color: #48acb2;
  cursor: pointer;
  margin: 0 5px;
}
.delete-icon {
  border-radius: 100%;
  width: 35px;
  height: 35px;
  background: #f4f7fe;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: -45px;
  cursor: pointer;
}

.list {
  padding-left: 30px;
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
  box-sizing: border-box;
  margin-top: 4px;
  overflow: hidden;
  padding: 0;
  pointer-events: auto;
  position: absolute;
  top: 100%;
  left: 0;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
  width: 100%;
  font: var(--contentSmall);
  font-weight: 400;
  line-height: 16px;
  text-transform: capitalize;
  li {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: 0;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  li:hover {
    color: #000000;
    background: rgba(0, 0, 0, 0.1);
  }
}
