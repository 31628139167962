.image-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
  gap: 15px;
  > img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid #c4c4c4;
  }
}
.container {
  background: #ffffff;
  border-radius: 10px;
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #666d81;
  font: var(--contentSmall);
  font-family: 'Roboto';
}

.right-container {
  > h3 {
    font: var(--contentLargeB);
  }
  div {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  span {
    display: inline-block;
    padding: 10px;
    background: rgba(255, 152, 1, 0.1);
    border-radius: 5px;
    font: var(--contentVerySmall);
    font-family: 'Roboto';
    color: var(--theme_secondary);
  }
  span:last-child {
    margin-right: 0;
  }
}
