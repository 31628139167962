.main-container {
  padding: 20px;
  background: #ffffff;
  border: 1px solid #c8c8c8;
  border-radius: 20px;
  cursor: pointer;
}

.header-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  > aside {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  > span {
    display: flex;
  }
}

.star-icon,
.location-icon {
  width: 15px;
  height: 15px;
  margin-right: 2px;
  path {
    fill: var(--theme_secondary);
  }
}
.map-content {
  font: var(--contentSmallB);
  cursor: pointer;
}
.map-content,
.rating-container {
  color: var(--theme_secondary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.rating-container {
  padding: 5px;
  background: rgba(255, 152, 1, 0.1);
  border-radius: 5px;
  font: var(--contentMedium);
}
.rating-container + span {
  color: #666d81;
  font: var(--contentMedium);
}

.rating-container + span::after,
.rating-container + span::before {
  content: '';
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0 10px;
}

.directory-type {
  display: inline-block;
  padding: 10px;
  border-radius: 5px;
}
.directory-type[data-directory='business'i] {
  background: #48acb2;
  color: #ffffff;
}
.directory-type[data-directory='ngo'i] {
  background: #05e8b1;
  color: #ffffff;
}
.heading {
  font: var(--contentLargeB);
  color: #131d3b;
  margin-bottom: 15px;
}
.sub-content {
  font: var(--contentSmall);
  color: #666d81;
  margin: 10px 0 15px;
}
.tag-container {
  display: flex;
  gap: 15px 30px;
  flex-wrap: wrap;
  margin-bottom: 15px;
  > span {
    display: inline-block;
    font: var(--contentMediumB);
  }
}

.announcement-container {
  background: #f4f7fe;
  border-radius: 20px;
  padding: 16px;
  > div {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    font-family: 'Roboto';
    > p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #666d81;
      font: var(--contentSmall);
    }
  }
  > span {
    display: block;
    text-align: right;
    color: var(--theme_secondary);
    font: var(--contentSmallB);
  }
}

.numbering-style {
  display: flex;
  width: 15px;
  height: 15px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: var(--theme_secondary);
  font: var(--contentVerySmallB);
  color: #ffffff;
}

.announcement-type {
  padding: 5px 10px;
  font: var(--contentMedium);
  border-radius: 5px;
  text-transform: capitalize;
}
.announcement-type[data-type='hiring'i] {
  color: #0194ff;
  background: rgba(1, 148, 255, 0.1);
}
.announcement-type[data-type='sales'i] {
  color: #48acb2;
  background: rgba(72, 172, 178, 0.1);
}
