.banner-container {
  width: 100%;
  height: 350px;
  position: relative;
  background-size: cover;
  background-position: center;
}

.image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #00031c 0%, rgba(0, 3, 28, 0) 100%);
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  h3 {
    color: #ffffff;
    font-size: 65px;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 700;
  }
  .stepper-container {
    display: flex;
    text-transform: uppercase;
    font: var(--contentVerySmall);
    color: #ffffff;
    color: var(--theme_secondary);
    p:last-of-type {
      color: #ffffff;
    }
    p::after {
      content: '>';
      margin: 0 5px;
    }
    p:last-of-type::after {
      content: '';
    }
  }
}
