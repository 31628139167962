.image-overlay {
  background: linear-gradient(
    to bottom,
    rgba(72, 172, 178, 0.9) 0%,
    rgba(16, 94, 99, 0.9) 100%
  );
  opacity: 0.9;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

.left-container {
  background-image: url('../../../libs/assests/image.png');
  height: 100%;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.left-container section {
  z-index: 2;
}

.input-base {
  border: 1px solid purple;
  border-radius: 5px;
  padding: 5px;
  height: 20px;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.container {
  margin: 20px 35px 30px;
  padding: 10px;
}

.container h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 50.0145px;
  line-height: 59px;
  text-align: center;

  color: #131d3b;
}

.text-content {
  font-style: normal;
  font-weight: 700;
  font-size: 55px;
  line-height: 100px;

  padding: 0 20px;
}
.text-content p,
.text-content h3 {
  margin: 0;
  padding: 0;
  color: #ffffff;
  text-align: center;
}
.text-content h3 {
  font-family: 'Roboto';
}

.text-content p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  font-family: 'Roboto Condensed';
  color: #ffffff;
  margin-top: 15px;
}
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../../libs/assests/background-image.png');
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 70px 0 30px;
}
