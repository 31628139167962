.background-img-container {
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
  padding: 10px;
  display: flex;
  align-items: flex-end;
}
.container {
  min-width: 200px;
  height: 275px;
  padding: 10px;
  background: #ffffff;
  border-radius: 20px;
}
.btn-style {
  font: var(--contentVerySmall);
}
.directives-card {
  background: #ffffff;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  border-radius: 10px;
  text-transform: uppercase;
  color: #131d3b;
  font: var(--contentVerySmall);
  > p {
    color: var(--theme_secondary);
    font: var(--contentMediumB);
  }
}
.footer-card {
  position: relative;
  background: #ffffff;
  font: var(--contentVerySmall);
  padding: 10px;
  border-radius: 10px;
  color: #131d3b;
  > p {
    color: #707789;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: justify;
  }
  > h5 {
    font: var(--contentSmallB);
    margin-bottom: 5px;
  }
  > span {
    float: right;
    right: 10px;
    top: 10px;
    color: var(--theme_secondary);
    background: rgba(255, 152, 1, 0.1);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  > p:nth-last-child(1) {
    color: var(--theme_secondary);
    text-transform: uppercase;
    font: var(--contentTiny);
    margin-top: 20px;
    cursor: pointer;
  }
}
