.cards-container {
  --items-per-screen: var(--items);
  --img-gap: 1rem;
  display: flex;
  flex-grow: 1;
  margin: 0 var(--img-gap);
  transform: translateX(calc(var(--slider-index) * -100%));
  transition: transform 1s ease-in-out;
}
.slider-card {
  flex: 0 0 calc(100% / var(--items-per-screen));
  max-width: calc(100% / var(--items-per-screen));
  padding: 0.25rem var(--img-gap);
}
.caurousel-btn-right {
  right: -20px;
  transform: rotate(180deg);
}
.caurousel-btn-left {
  left: -20px;
}

.caurousel-btn-right,
.caurousel-btn-left {
  background: #ffffff;
  color: #131d3b;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  z-index: 999;
  cursor: pointer;

  box-shadow: 0px 8px 8.246px rgba(0, 0, 0, 0.1);
  > img {
    width: 20px;
    height: 20px;
  }
}

.carousel-container,
.carousel-container1 {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}
.carousel-container1 {
  right: -10px;
}
.carousel-container {
  left: -10px;
}

@media (max-width: 1000px) {
  .cards-container {
    --items-per-screen: 2;
  }
}

@media (max-width: 500px) {
  .cards-container {
    --items-per-screen: 1;
  }
}
