.container {
  background: #ffffff;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  padding: 20px;
  margin-top: 20px;
}

.header-container {
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
  margin-bottom: 15px;
  .sub-content {
    color: #666d81;
    font: var(--contentVerySmall);
    margin-top: 5px;
  }
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
  padding-bottom: 20px;
}
