.header-banner {
  background: #235963;
  min-height: 20px;
  width: 100%;
}

.header-section {
  background: #46a1a7;
  min-height: 80px;
  display: flex;
  padding: 20px 50px 0 50px;
  justify-content: space-between;
  align-items: flex-start;

  > img {
    display: flex;
    width: 129px;
  }
}
.input-container {
  margin-right: 10px;
  position: relative;
  height: 100%;
  flex: 1;
  margin: 0 5px;
}
.support-container {
  height: 30px;
  width: 30px;
  color: #ffffff;
  margin: 0 5px 0 5px;
  border-radius: 50px;
  border: 2px solid #ffffff;
  img {
    padding: 5px;
    width: 25px;
  }
}
.support-content {
  font: var(--contentVerySmall);
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
  white-space: nowrap;

  color: #ffffff;
  span {
    display: block;
  }
}

.list-container {
  display: flex;
  background: #46a1a7;
  height: 50px;
  position: absolute;
  border-radius: 5px 0px 0px 5px;
  z-index: 100;
  width: 100%;
  bottom: -30px;
  filter: drop-shadow(0px 7px 10px rgba(55, 88, 186, 0.12));
  ul {
    flex: 1;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px 10px;
  }
  ul li {
    list-style: none;
    display: inline-block;
    font: var(--contentSmall);
    color: #ffffff;
    cursor: pointer;
  }
  ul li[data-active='true'] {
    border-bottom: 2px solid var(--theme_secondary);
  }
  > section {
    background: #ffffff;
    width: 223px;
    display: flex;
    justify-content: center;
    align-items: center;
    font: var(--contentSmall);
    font-weight: 700;
    color: #131d3b;
    cursor: pointer;
    > span {
      margin-left: 5px;
      font-size: 18px;
    }
  }
}

.user-icon {
  object-fit: cover;
  width: 53px;
  height: 53px;
  border-radius: 50px;
  overflow: hidden;
  border: 2px solid #ffffff;
}
.user-name {
  font: var(--contentMediumB) !important;
}

.user-container {
  display: flex;
  align-items: center;
  gap: 5px;
  > section {
    > p {
      margin-top: 1px;
      font: var(--contentSmall);
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.drop-down {
  width: 10px;
  path {
    width: 10px;
    fill: #ffffff;
    fill-opacity: 1;
  }
}
