.container {
  position: relative;
  .delete-icon {
    border-radius: 100%;
    width: 35px;
    height: 35px;
    background: #f4f7fe;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: -45px;
    cursor: pointer;
  }
}
