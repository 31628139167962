.header-container {
  display: flex;
  align-items: center;
  > div {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 20px;
    align-items: center;
    font: var(--contentSmall);
    color: #131d3b;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #c8c8c8;
  border-radius: 20px;
}
