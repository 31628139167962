.header-container {
  display: flex;
  gap: 30px;
  > div {
    > p {
      font: var(--contentVerySmall);
      color: #666d81;
      margin-bottom: 5px;
      text-transform: uppercase;
    }
    > h3 {
      font: var(--contentExtraLargeB);
      color: #131d3b;
    }
    > div {
      font: var(--contentSmall);
      color: #666d81;
      line-height: 26px;
      margin-top: 10px;
    }
  }
}

.container {
  background: #ffffff;
  padding: var(--containerPadding);
  > div {
    font: var(--contentSmall);
    color: #666d81;
    line-height: 26px;
    margin-top: 10px;
  }
}
