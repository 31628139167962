.table-container {
  th,
  td {
    text-align: left;
    color: #131d3b;
    padding: 0 10px;
    font: var(--contentLargeB);
  }
  td {
    padding: 20px 10px;
    color: #666d81;
    font: var(--contentMedium);
  }
  td {
    border-bottom: 1px solid #666d8130;
  }
  thead {
    > tr th:first-of-type {
      width: 40%;
    }
  }
}
