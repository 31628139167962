.container {
  display: flex;
  padding: 70px 280px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  section {
    width: 350px;
    text-align: center;
    margin-bottom: 15px;
    > p {
      color: #666d81;
      font: var(--contentMedium);
    }
    > h3 {
      font: var(--headingLarge);
      color: #131d3b;
    }
  }
  .grid-container {
    color: var(--theme_secondary);
    font: var(--headingLarge);
    font-size: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font: var(--contentSmall);
      color: #131d3b;
      text-transform: capitalize;
    }
  }
}
