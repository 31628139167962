.input-container {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  min-height: 150px;
  margin-bottom: 5px;
  p::before {
    content: '' !important;
  }
}
.container {
  margin-bottom: 5px;
  & label {
    font: var(--contentMedium);
    font-weight: 700;
    line-height: 19px;
    color: #131d3b;
    display: block;
    margin-bottom: 5px;
  }
}
label > span {
  color: #ff5555;
}
