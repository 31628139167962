.list-item {
  display: flex;
  align-items: center;
  gap: 15px;
  font: var(--contentSmall);
  color: #131d3b;
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
  padding: 15px 0;
  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--theme_secondary);
    font: var(--contentVerySmall);
    color: #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
  > div {
    flex: 1;
    color: #666d81;
  }
}
.list-item:nth-last-of-type(1) {
  border-bottom: 0px solid;
}

.list-container {
  background: #f4f7fe;
  border-radius: 20px;
  padding: 0 15px;
}

.container {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 15px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
