.header-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
  padding: 10px;
  margin-bottom: 10px;
  > p {
    font: var(--headingMedium);
  }
  > span {
    background: #666d81;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 14px;
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
}
