.container {
  display: flex;
  height: 500px;
  section {
    background: #f2f3f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1 1 50%;
    position: relative;
    .directives-card {
      background: #ffffff;
      display: inline-block;
      position: absolute;
      top: 10px;
      left: 10%;
      padding: 5px;
      border-radius: 10px;
      min-width: 80px;
      text-transform: uppercase;
      color: #131d3b;
      font: var(--contentVerySmall);
      > p {
        color: var(--theme_secondary);
        font: var(--contentMediumB);
      }
    }
    > img {
      width: 100%;
      height: 75%;
    }
    .images-footer {
      position: relative;
      width: 100%;
      height: 25%;
      background-color: #235963;
      padding: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font: var(--contentMedium);
      > span {
        position: absolute;
        height: 100%;
        left: 0;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        text-align: center;
        background-color: var(--theme_secondary);
        padding: 5px;
        font: var(--contentVerySmall);
        letter-spacing: 2px;
        text-transform: uppercase;
      }
      > img {
        height: 100%;
        margin-right: 15px;
      }
    }
  }
}

.head-container {
  margin: 0 20% 0 25%;
  p,
  h3 {
    width: 200px;
  }
  h3 {
    font: var(--headingMedium);
    color: #131d3b;
    margin-bottom: 15px;
  }
  p {
    font: var(--contentMedium);
    color: #666d81;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  ul > li {
    font: var(--contentMedium);
    font-weight: 400;
    line-height: 24px;
    color: #666d81;
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
    > span {
      display: flex;
      min-width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: var(--theme_secondary);
      color: #ffffff;
      justify-content: center;
      align-items: center;
      font: var(--contentMedium);
      margin-right: 5px;
      padding: 5px;
    }
  }
  > span {
    cursor: pointer;
    font: var(--contentMediumB);
    padding: 10px;
    background-color: #ffffff;
    color: #131d3b;
    margin-top: 15px;
    display: inline-block;
  }
}
