.table-header {
  th {
    background: #f4f7fe;
    padding: 1rem;
    font: var(--contentVerySmallB);
    text-align: left;
  }
  th:first-of-type {
    border-radius: 20px 0 0 20px;
  }
  th:last-of-type {
    border-radius: 0 20px 20px 0;
  }
}

.table-container {
  border-collapse: collapse;
  width: 100%;
  //   table-layout: fixed;
}

.table-row {
  color: #666d81;
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
  td {
    padding: 1rem;
    text-align: left;
    font: var(--contentVerySmall);
  }
}

.container {
  background: #ffffff;
}
