.section-container {
  position: relative;
  background: #edf7f7;
  padding: 20px 280px;
}

.section-container[data-no-background='true'] {
  padding: 0 70px;
  background: #f5f5f5;
}

.card-list-container {
  display: flex;
  gap: 20px;
  margin: 10px 0 20px;
  scroll-snap-align: start;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
  > span {
    position: absolute;
    background: #ffffff;
    color: #131d3b;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    top: 45%;
    z-index: 1;

    box-shadow: 0px 8px 8.246px rgba(0, 0, 0, 0.1);
    > img {
      width: 20px;
      height: 20px;
    }
  }
  > span:first-child {
    left: -30px;
  }
  > span:nth-last-child(1) {
    right: -30px;
    transform: rotate(180deg);
  }
}

.card-list-container::-webkit-scrollbar {
  display: none;
}


