.container {
  width: 100%;
  margin: 0 0 10px;
}

.container label {
  font: var(--contentMedium);
  font-weight: 700;
  line-height: 19px;
  color: #131d3b;
  display: block;
  margin-bottom: 5px;
}
label > span {
  color: #ff5555;
}

.container i {
  color: #48acb2;
  cursor: pointer;
  margin: 0 5px;
}
