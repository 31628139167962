.container {
  padding: var(--containerMargin);
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(90vh - 103px);
}
.main-container {
  background: #ffffff;
}

.container[data-no-side-margin='true'] {
  padding: var(--containerMarginTop);
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
}
