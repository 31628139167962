.check-box-group {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  gap: 15px;
}

.lable-style {
  font: var(--contentMedium);
  font-weight: 700;
  line-height: 19px;
  color: #131d3b;
  margin: 0;
  padding: 0;
  margin: 5px 0 10px;
  display: inline-block;
}

.lable-style > span {
  color: #ff5555;
}
