.container {
  background: #ffffff;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  border-radius: 25px;

  h4 {
    font: var(--contentSmallB);
    color: #131d3b;
    text-transform: capitalize;
    margin-bottom: 5px;
  }
  p {
    font: var(--contentSmall);
    color: #666d81;
    line-height: 19px;
    margin-bottom: 10px;
  }
}
.term-date {
  color: #666d81;
  font: var(--contentSmallB);
}

.header-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
  margin-bottom: 15px;
  padding-bottom: 10px;
  align-items: center;
  > h3 {
    font: var(--contentLargeB);
    color: #131d3b;
  }
}
