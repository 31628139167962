.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  min-height: 35px;
}

.container {
  padding-bottom: 20px;
}
.section-container {
  padding: var(--containerPadding);
}

.left-container {
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 20px;
  width: 100%;
}

.image-container {
  width: 100%;
  height: 250px;
  position: relative;
  margin-bottom: 30px;
  background-size: cover;
  background-position: center;
  border-radius: 25px 25px 0px 0px;
}
.stepper-container {
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
  border-top: 1px solid rgba(102, 109, 129, 0.3);
  padding: 10px 0 10px;
}

.image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    16.19deg,
    #000000 13.53%,
    rgba(0, 0, 0, 0) 87.86%
  );
}
