.stepper-container {
  border-bottom: 1px solid rgba(102, 109, 129, 0.3);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  > section {
    align-self: flex-start;
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}
