.container {
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  max-width: 700px;

  font: var(--contentMedium);
  .address-style {
    margin-bottom: 10px;
    color: #666d81;
    font:var(--contentSmall)
  }
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.icon-container {
  display: flex;
  gap: 20px;
  font: var(--contentSmall);
  color: #48acb2;
  margin-top: 15px;
  > p {
    display: flex;
    align-items: center;
  }
  svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  path {
    fill: #48acb2;
  }
}

.location-style {
  font: var(--contentMedium);
  font-weight: 700;
  margin: 10px 0;
  color: #666d81;
  > span {
    font-weight: 400;
  }
}
