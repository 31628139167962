.form-container {
  background: #f4f7fe;
  border-radius: 20px;
  padding: 20px;
  margin: 15px 0;
  position: relative;
}

.text-holder {
  font: var(--contentSmall);
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  color: #666d81;
}

.delete-icon {
  border-radius: 100%;
  width: 35px;
  height: 35px;
  background: #f4f7fe;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: -45px;
  cursor: pointer;
}
