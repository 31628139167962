.container {
  width: 378px;
  height: 350px;
  filter: drop-shadow(0px 6px 9px rgba(0, 0, 0, 0.15));
  border-radius: 25px;
  background: #ffffff;
}

.service-image {
  background: #ffffff;
  position: relative;
  width: 100%;
  height: 70%;
  background-size: cover;
  z-index: 0;
  border-radius: 25px;
  cursor: pointer;
}

.img-overlay {
  > p {
    font: var(--contentMediumB);
    color: #131d1b;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 30%;
  svg {
    fill: #fff !important;
  }
}
.container1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image-container {
    > img {
      width: 150px;
      height: 150px;
    }
  }

  > p {
    font: var(--contentMediumB);
    color: #131d3b;
    margin: 5px 0;
    text-align: center;
  }
}
