.container {
  width: 100%;
  margin-bottom: 5px;
  > label {
    font: var(--contentMedium);
    font-weight: 700;
    line-height: 19px;
    color: #131d3b;
    display: block;
    margin-bottom: 5px;
    > span {
      color: #ff5555;
    }
  }
}

.option-style {
  font-family: 'Roboto Condensed';
  &-disable {
    opacity: 0.5;
  }
}
