.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  min-height: 35px;
}

.container {
  padding-bottom: 20px;
}

.section-container {
  padding: var(--containerPadding);
}
