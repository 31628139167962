.container {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(27, 47, 118, 0.15);
  border-radius: 20px;
  min-width: 300px;
  padding: 15px;
  gap: 10px;
  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    min-width: 30px;
    background: rgba(72, 172, 178, 0.1);
    border-radius: 50%;
  }
  > section {
    > h3 {
      color: #131d3b;
      font: var(--contentMediumB);
    }
    > p {
      font: var(--contentSmall);
      color: #666d81;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
.container[data-active='true'] {
  background: #48acb2;
  box-shadow: 0px 4px 15px rgba(27, 47, 118, 0.15);
  color: #ffffff;
  > span {
    background-color: rgba(255, 255, 255, 0.1);
    svg {
      path {
        fill: #ffffff;
      }
    }
  }
  > section {
    > h3 {
      color: #ffffff;
      font: var(--contentMediumB);
    }
    > p {
      font: var(--contentSmall);
      color: #ffffff;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
