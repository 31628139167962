.container {
  min-height: 300px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.image-container {
  flex: 1;
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
  border-radius: 5px;
  margin-bottom: 35px;
}
.resource-icon {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background: var(--theme_secondary);
  position: absolute;
  left: 25px;
  bottom: -25px;
  display: flex;
  justify-content: center;
  align-items: center;
  > img {
    width: 85%;
    height: 85%;
    object-fit: cover;
  }
}
.resource-icon[data-even='true']{
  background: #ffffff;
}
.directives-card {
  background: #ffffff;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  border-radius: 10px;
  text-transform: uppercase;
  color: #131d3b;
  font: var(--contentVerySmall);
  > p {
    color: var(--theme_secondary);
    font: var(--contentMediumB);
  }
}

.footer-card {
  position: relative;
  background: #ffffff;
  font: var(--contentVerySmall);

  border-radius: 10px;
  color: #131d3b;
  > p {
    color: #707789;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  > h5 {
    font: var(--contentMediumB);
    margin-bottom: 5px;
  }
  > p:nth-last-child(1) {
    color: var(--theme_secondary);
    text-transform: uppercase;
    font: var(--contentVerySmall);
    margin-top: 20px;
    cursor: pointer;
  }
}
