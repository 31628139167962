.label-style {
  font: var(--contentLargeB);
  font-weight: 700;
  display: flex;
  align-items: center;
  > .main-label {
    display: flex;
    white-space: nowrap;
    color: #131d3b !important;
  }
  > section {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
}

.label-style[center='true'] {
  text-align: center;
  .main-label {
    text-align: center;
    display: block;
    width: 100%;
  }
}
.label-style[data-heading='true'] {
  font: var(--headingLarge);
}

.label-style[textlabel='true'] {
  font: var(--contentMedium);
  font-weight: 700;
  line-height: 20px;
  color: #131d3b;
  margin-bottom: 10px;
}
.label-style[textlabel='true'] .main-label > span {
  color: #ff5555;
}

.sub-content {
  color: rgba(102, 109, 129, 0.5) !important;
  font: var(--contentVerySmall);
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
