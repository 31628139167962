.container {
  position: relative;

  > section {
    height: 500px;
    background-size: cover;
    background-position: center;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    > p {
      font: var(--contentSmall);
    }
    > h1 {
      font-size: 80px;
    }
    > h1:last-of-type {
      margin-bottom: 30px;
    }
  }
}
.search-container {
  width: 50%;
  height: 150px;
  border-radius: 25px;
  position: absolute;
  background: #ffffff;
  bottom: -75px;
  left: 25%;
  z-index: 99;
  padding: 15px;
  > section {
    margin-top: 20px;
    width: 100%;
  }
}
.indicator {
  display: flex;
  gap: 5px;
  margin: 10px 0;
  flex: 1;
  > span {
    border: 4px solid #ffffff;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    opacity: 0.4;
    cursor: pointer;
  }
  > span[data-active='true'] {
    opacity: 1;
  }
}
